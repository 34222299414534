<template>
<div>
    <v-dialog v-model="$store.state.subFieldsDialog" width="800" persistent>
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title> الحقول الفرعية</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form>
                <v-container fluid>
                    <v-card outlined>
                        <v-container fluid>
                            <v-btn @click="openAddDialog()" large color="primary" class="btn">
                                إضافة حقل
                            </v-btn>
                            <v-divider class="mt-2" />
                            <Loading v-if="$global.state.loading" />
                            <v-data-table v-if="!$global.state.loading" class="mytable" :items-per-page="$global.state.filter.pageSize" :items="forms" :loading="$global.state.loading" :headers="headers" hide-default-footer :loading-text="$t('loading')" :no-data-text="$t('no-data')" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

                                <template v-slot:item.isRequired="{ item }">
                                    <v-chip v-if="item.isRequired == true">إلزامي</v-chip>
                                    <v-chip v-if="item.isRequired == false">إختياري</v-chip>
                                </template>

                                <template v-slot:item.actions="{ item, index }">
                                    <v-tooltip bottom="">
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                                                <v-icon color="error"> delete </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('hthf') }}</span>
                                    </v-tooltip>

                                    <v-tooltip bottom="">
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="openEditDialog(item)" v-on="on" icon>
                                                <v-icon color="info"> edit </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('edit') }}</span>
                                    </v-tooltip>

                                </template>
                            </v-data-table>
                        </v-container>
                    </v-card>
                </v-container>
            </v-form>

        </v-card>
    </v-dialog>

    <v-dialog v-model="actionDialog" width="400">
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title> {{actionDialog == 'add' ? 'إضافة' : 'تعديل'}} حقل</v-toolbar-title>
                <v-spacer />
                <v-btn @click="closeActionDialog()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" >
                <v-container>
                    <v-card outlined>
                        <v-container>

                            <v-row>

                                <v-col cols="12" md="12">
                                    <h3 class="mb-2">اختر الحقل <span class="required">*</span></h3>
                                    <v-autocomplete clearable v-model="item.fieldId" item-text="name" item-value="id" :items="$global.state.fields" class="mr-1" placeholder="الحقول" :loading="$global.state.loading" :no-data-text="$t('no-data')" hide-details="" outlined="" filled="" />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <h3 class="mb-2">{{ $t('altslsl') }} <span class="required">*</span></h3>
                                    <v-text-field v-model="item.sequence" :placeholder="$t('altslsl')" filled outlined hide-details="auto" />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <h3 class="mb-2">العنوان <span class="required">*</span></h3>
                                    <v-text-field v-model="item.title" placeholder="العنوان" filled outlined hide-details="auto" />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <h3 class="mb-2">العنوان الفرعي <span class="required">*</span></h3>
                                    <v-text-field v-model="item.subTitle" placeholder="العنوان الفرعي" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="12">
                                    <!-- <h3 class="mb-2">نوع الإجراء <span class="required">*</span></h3> -->
                                    <v-radio-group v-model="item.isRequired">
                                        <v-radio :value="true" label="إلزامي" />
                                        <v-radio :value="false" label="إختياري" />
                                    </v-radio-group>
                                </v-col>

                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :loading="$global.state.loading" x-large color="primary" class="btn">{{ $t("save") }}</v-btn>
                            <v-btn @click="closeActionDialog()" x-large color="error" class="mr-2 btn" outlined>{{ $t("cancel") }}</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            dialogType: 'add',
            fieldChildId: '',
            item: {},
            actionDialog: false,
            headers: [
                {
                    text: this.$t('altslsl'),
                    value: "sequence",
                },
                {
                    text: "الحقل",
                    value: "field.name",
                },
                {
                    text: "العنوان",
                    value: "title",
                },
                {
                    text: "العنوان الفرعي",
                    value: "subTitle",
                },
                
                // {
                //     text: "التقسيم",
                //     value: "cols",
                // },
                {
                    text: "القيم المدخلة",
                    value: "isRequired",
                },
                {
                    text: "",
                    value: "actions",
                },
            ],
            forms: [],
        };
    },
    mounted() {

        this.reset();
        this.$eventBus.$on("fill-fields", () => {
            this.forms = [];
            this.getItems();
            this.$global.dispatch(`getField`);
            console.log('this.$store.state.itemDetails')
        console.log(this.$store.state.itemDetails)
        });
        
    },
    methods: {
        reset() {
            this.item = {
                "title": "",
                "subTitle": "",
                "sequence": 0,
                "isRequired": false,
                "fieldId": "",
                "parentFieldId": ""
            }
        },
        async getItems() {
            // this.$global.dispatch(`getActions`, this.$store.state.itemDetails.id);
            this.$global.state.loading = true;
            try {
                const res = await this.$http.get(`FieldChild?ParentFieldId=${this.$store.state.itemDetails.id}&IsDeleted=false&PageIndex=1&PageSize=1000`);
                this.forms = res.data.result;
                this.forms.sort((a, b) => a.sequence - b.sequence);
                console.log('res.data.result')
                console.log(res.data.result)
                // this.state.length =
                //   res.data.count == undefined
                //     ? 0
                //     : Math.ceil(res.data.count / this.state.filter.pageSize);
            } catch (err) {
                console.log(err);
                this.$service.faild(err.response.data.message);
            } finally {
                this.$global.state.loading = false;
            }
        },
        async deleteItem(id, i) {
            await this.$service.deleteItem(id, `FieldChild`, `delete`);

            await this.$eventBus.$on(`delete`, () => {
                setTimeout(() => {this.getItems() }, 10);
            });
        },
        submit() {

   
            if (this.dialogType == "add") {
                this.addItem();
            } else {
                this.editItem();
            }
        },
        async addItem() {
            this.item.parentFieldId = this.$store.state.itemDetails.id;
            this.$global.state.loading = true;
            try {
                await this.$http.post(`FieldChild`, this.item);
                this.$service.success(this.$t("tm-alidhafh-bnjah"));
                await this.closeActionDialog();
                await this.getItems();
            } catch (err) {
                this.$service.faild(err.response.data.message);
            } finally {
                this.$global.state.loading = false;
            }
        },
        editItem(fieldChildId) {
            console.log('fieldChildId')
            console.log(fieldChildId)
            this.$global.state.loading = true;
            this.$http.put(`FieldChild/${fieldChildId}`, this.item).then(res => {
                this.closeActionDialog();
                this.$service.success(this.$t('tm-altadyl-bnjah'))
                this.getItems();
            }).catch(err => {
                this.$service.faild(err.response.data.message);
            }).finally(() => this.$global.state.loading = false);
        },
        close() {
            this.$global.state.forms = [];
            // this.$eventBus.$off(`fill-fields`);
            this.$store.commit("setSubFieldsDialog");
            // this.reset()
            // this.resetValidation()
            this.$store.commit("setItemDetails", {});

        },
        closeActionDialog() {
            this.reset();
            // this.resetValidation();
            this.actionDialog = false;
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        getSubNodes() {
            this.$global.dispatch(`getNodes`);
        },

        openEditDialog(item) {
            console.log('item')
            console.log(item)
            this.dialogType = "edit";
            this.item.title = item.title;
            this.item.subTitle = item.subTitle;
            this.item.sequence = item.sequence;
            this.item.parentFieldId = item.id;
            this.item.fieldId = item.field.id;
            this.item.isRequired = item.isRequired;
            this.actionDialog = true;
        },

        openAddDialog() {
            this.dialogType = "add";
            this.actionDialog = true;
        },
    },
}
</script>
